export const translationKey = {
  AccessibilityLabelADenseTable: 'AccessibilityLabelADenseTable',
  AccessibilityLabelSecondaryCheckbox: 'AccessibilityLabelSecondaryCheckbox',
  AlertBodyCancelEntityForm: 'AlertBodyCancelEntityForm',
  AlertBodyCancelOrganisationForm: 'AlertBodyCancelOrganisationForm',
  AlertBodyCancelYoungForm: 'AlertBodyCancelYoungForm',
  AlertBodyConfirmDeleteYoungPerson: 'AlertBodyConfirmDeleteYoungPerson',
  AlertBodyDistributorUploadFail: 'AlertBodyDistributorUploadFail',
  AlertBodyDistributorsUploaded: '{{file}} has been successfully uploaded',
  AlertBodyDuplicateOrganisation: 'AlertBodyDuplicateOrganisation',
  AlertBodyInviteSent: 'AlertBodyInviteSent',
  AlertBodyInvitesSent: 'AlertBodyInvitesSent',
  AlertBodyPasswordReset: 'AlertBodyPasswordReset',
  AlertBodyResendGuardianInvite: 'AlertBodyResendGuardianInvite',
  AlertBodyResetLinkSent: 'AlertBodyResetLinkSent',
  AlertBodyStartProcessing: 'AlertBodyStartProcessing',
  AlertBodyStartProcessingMHSDS: 'AlertBodyStartProcessingMHSDS',
  AlertBodyUnsavedDataWarning: 'AlertBodyUnsavedDataWarning',
  AlertIsUserSure: 'AlertIsUserSure',
  AlertNoFile: 'AlertNoFile',
  AlertTitleAdminInvite: 'AlertTitleAdminInvite',
  AlertTitleCancelAdminInvite: 'AlertTitleCancelAdminInvite',
  AlertTitleCancelEntityForm: 'AlertTitleCancelEntityForm',
  AlertTitleCancelOrganisationForm: 'AlertTitleCancelOrganisationForm',
  AlertTitleCancelYoungForm: 'AlertTitleCancelYoungForm',
  AlertTitleConfirmDeleteYoungPerson: 'AlertTitleConfirmDeleteYoungPerson',
  AlertTitleDeactivationReason: 'AlertTitleDeactivationReason',
  AlertTitleDistributorUploadFail: 'AlertTitleDistributorUploadFail',
  AlertTitleDistributorsUploaded: 'AlertTitleDistributorsUploaded',
  AlertTitleDuplicateOrganisation: 'AlertTitleDuplicateOrganisation',
  AlertTitleInvitesSent: 'AlertTitleInvitesSent',
  AlertTitleResendGuardianInvite: 'AlertTitleResendGuardianInvite',
  AlertTitleStartProcessing: 'AlertTitleStartProcessing',
  AlertTitleSuccess: 'AlertTitleSuccess',
  AltGameLogo: 'AltGameLogo',
  AltUserUploadedLogo: 'AltUserUploadedLogo',
  ButtonCancel: 'ButtonCancel',
  ButtonCheckMyEligibility: 'ButtonCheckMyEligibility',
  ButtonClose: 'ButtonClose',
  ButtonCompletedSurveys: 'ButtonCompletedSurveys',
  ButtonCreateDistributor: 'ButtonCreateDistributor',
  ButtonCreateEntity: 'ButtonCreateEntity',
  ButtonCreateOrganisation: 'ButtonCreateOrganisation',
  ButtonDownloadFile: 'ButtonDownloadFile',
  ButtonDownloadReport: 'ButtonDownloadReport',
  ButtonGetUpdatedData: 'ButtonGetUpdatedData',
  ButtonGetHubUsageData: 'ButtonGetHubUsageData',
  ButtonGoalBasedOutcomes: 'ButtonGoalBasedOutcomes',
  ButtonHide: 'ButtonHide',
  ButtonHideOrViewCompletedSurveys: 'ButtonHideOrViewCompletedSurveys',
  ButtonInviteNewUser: 'ButtonInviteNewUser',
  ButtonInviteYoungPerson: 'ButtonInviteYoungPerson',
  ButtonLogoutUser: 'ButtonLogoutUser',
  ButtonResendInvite: 'ButtonResendInvite',
  ButtonSave: 'ButtonSave',
  ButtonSendInvites: 'ButtonSendInvites',
  ButtonShow: 'ButtonShow',
  ButtonSignIn: 'ButtonSignIn',
  ButtonSubmit: 'ButtonSubmit',
  ButtonRegister: 'ButtonRegister',
  ButtonTitleBack: 'ButtonTitleBack',
  ButtonTitleSendInvite: 'ButtonTitleSendInvite',
  ButtonUploadFile: 'ButtonUploadFile',
  ChoiceLabelFemale: 'ChoiceLabelFemale',
  ChoiceLabelMale: 'ChoiceLabelMale',
  ChoiceLabelNA: 'ChoiceLabelNA',
  ChoiceLabelNo: 'ChoiceLabelNo',
  ChoiceLabelNone: 'ChoiceLabelNone',
  ChoiceLabelYes: 'ChoiceLabelYes',
  ChoiceNo: 'ChoiceNo',
  ChoiceYes: 'ChoiceYes',
  ComponentGameTimeAverage: 'ComponentGameTimeAverage',
  ConfirmRestoreName: 'ConfirmRestoreName',
  ConfirmationOk: 'ConfirmationOk',
  Confirm: 'Confirm',
  DataTipReflectionInfo: 'DataTipReflectionInfo',
  DateFormat: 'DateFormat',
  DayMonday: 'DayMonday',
  DayMondayShort: 'DayMondayShort',
  DayTuesday: 'DayTuesday',
  DayTuesdayShort: 'DayTuesdayShort',
  DayWednesday: 'DayWednesday',
  DayWednesdayShort: 'DayWednesdayShort',
  DayThursday: 'DayThursday',
  DayThursdayShort: 'DayThursdayShort',
  DayFriday: 'DayFriday',
  DayFridayShort: 'DayFridayShort',
  DaySaturday: 'DaySaturday',
  DaySaturdayShort: 'DaySaturdayShort',
  DaySunday: 'DaySunday',
  DaySundayShort: 'DaySundayShort',
  DOBFieldMessage: 'DOBFieldMessage',
  DistributorWeeklyEmailSettingsEnableEmail: 'DistributorWeeklyEmailSettingsEnableEmail',
  DistributorWeeklyEmailSettingsReportDay: 'DistributorWeeklyEmailSettingsReportDay',
  DistributorWeeklyEmailSettingsReportTime: 'DistributorWeeklyEmailSettingsReportTime',
  ErrorApologyForProblem: 'ErrorApologyForProblem',
  ErrorConfirmPassword: 'ErrorConfirmPassword',
  ErrorDistributorMustBeSelected: 'ErrorDistributorMustBeSelected',
  ErrorEnterPassword: 'ErrorEnterPassword',
  ErrorGameKeysSpecifiedMustBePositive: 'ErrorGameKeysSpecifiedMustBePositive',
  ErrorInvalidEmail: 'ErrorInvalidEmail',
  ErrorLogoRequired: 'ErrorLogoRequired',
  ErrorMinimumPassword: 'ErrorMinimumPassword',
  ErrorMissingEmail: 'ErrorMissingEmail',
  ErrorMissingName: 'ErrorMissingName',
  ErrorMissingPhoneNumber: 'ErrorMissingPhoneNumber',
  ErrorMissingRelationship: 'ErrorMissingRelationship',
  ErrorNoGameAccess: 'ErrorNoGameAccess',
  ErrorPasswordMatch: 'ErrorPasswordMatch',
  ErrorPasswordRequired: 'ErrorPasswordRequired',
  ErrorRequired: 'ErrorRequired',
  ErrorSpecifyAddress: 'ErrorSpecifyAddress',
  ErrorSpecifyBuyerName: 'ErrorSpecifyBuyerName',
  ErrorSpecifyDOB: 'ErrorSpecifyDOB',
  ErrorSpecifyGender: 'ErrorSpecifyGender',
  ErrorSpecifyEthnicity: 'ErrorSpecifyEthnicity',
  ErrorSpecifyDisability: 'ErrorSpecifyDisability',
  ErrorSpecifyDisabilityTypes: 'ErrorSpecifyDisabilityTypes',
  ErrorSpecifyDistributorAddress: 'ErrorSpecifyDistributorAddress',
  ErrorSpecifyDistributorName: 'ErrorSpecifyDistributorName',
  ErrorSpecifyEntityName: 'ErrorSpecifyEntityName',
  ErrorSpecifyFirstName: 'ErrorSpecifyFirstName',
  ErrorSpecifyGameKeysAllocatedToEntity: 'ErrorSpecifyGameKeysAllocatedToEntity',
  ErrorSpecifyGuardianFirstName: 'ErrorSpecifyGuardianFirstName',
  ErrorSpecifyGuardianLastName: 'ErrorSpecifyGuardianLastName',
  ErrorSpecifyMarketingVisibilityResponse: 'ErrorSpecifyMarketingVisibilityResponse',
  ErrorSpecifyLastName: 'ErrorSpecifyLastName',
  ErrorSpecifyNumber: 'ErrorSpecifyNumber',
  ErrorSpecifyOrganisationAddress: 'ErrorSpecifyOrganisationAddress',
  ErrorSpecifyOrganisationGameKeys: 'ErrorSpecifyOrganisationGameKeys',
  ErrorSpecifyOrganisationName: 'ErrorSpecifyOrganisationName',
  ErrorSpecifyOrganisationPostcode: 'ErrorSpecifyOrganisationPostcode',
  ErrorSpecifyPhoneNumber: 'ErrorSpecifyPhoneNumber',
  ErrorSpecifyPointPersonEmail: 'ErrorSpecifyPointPersonEmail',
  ErrorSpecifyPointPersonName: 'ErrorSpecifyPointPersonName',
  ErrorSpecifyPointPersonNameOrg: 'ErrorSpecifyPointPersonNameOrg',
  ErrorSpecifyPointPersonPhone: 'ErrorSpecifyPointPersonPhone',
  ErrorSpecifyRelationship: 'ErrorSpecifyRelationship',
  ErrorSuggestedBrowsers: 'ErrorSuggestedBrowsers',
  ErrorUnknown: 'ErrorUnknown',
  ErrorUnsupportedBrowser: 'ErrorUnsupportedBrowser',
  ErrorUrlIncomplete: 'ErrorUrlIncomplete',
  ErrorValidPhoneNumber: 'ErrorValidPhoneNumber',
  FaqAnswerContact: 'FaqAnswerContact',
  FaqAnswerForgotGameKey: 'FaqAnswerForgotGameKey',
  FaqAnswerHowDoIChooseGamePlayersCots: 'FaqAnswerHowDoIChooseGamePlayersCots',
  FaqAnswerHowDoIChooseGamePlayersLn: 'FaqAnswerHowDoIChooseGamePlayersLn',
  FaqAnswerHowDoIChooseGamePlayersLnAgeRange: 'FaqAnswerHowDoIChooseGamePlayersLnAgeRange',
  FaqAnswerHowDoIChooseGamePlayersLnIsFor: 'FaqAnswerHowDoIChooseGamePlayersLnIsFor',
  FaqAnswerHowDoIChooseGamePlayersLnIsNotFor: 'FaqAnswerHowDoIChooseGamePlayersLnIsNotFor',
  FaqAnswerHowDoIChooseGamePlayersMildToModerateAnxiety: 'FaqAnswerHowDoIChooseGamePlayersMildToModerateAnxiety',
  FaqAnswerHowDoIChooseGamePlayersPanicDisorder: 'FaqAnswerHowDoIChooseGamePlayersPanicDisorder',
  FaqAnswerHowToIntroduceAGuardian: ' FaqAnswerHowToIntroduceAGuardian',
  FaqAnswerHowToIntroduceAGuardianExpectSms: ' FaqAnswerHowToIntroduceAGuardianExpectSms',
  FaqAnswerHowToIntroduceAGuardianExplainTheGame: ' FaqAnswerHowToIntroduceAGuardianExplainTheGame',
  FaqAnswerHowToIntroduceAGuardianGuardianIsNotToBlame: ' FaqAnswerHowToIntroduceAGuardianGuardianIsNotToBlame',
  FaqAnswerHowToIntroduceAGuardianSomeGuardiansHaveAnxieties: ' FaqAnswerHowToIntroduceAGuardianSomeGuardiansHaveAnxieties',
  FaqAnswerHowToIntroduceAGuardianSomeGuardiansMayBeAware: ' FaqAnswerHowToIntroduceAGuardianSomeGuardiansMayBeAware',
  FaqAnswerHowToSendGameInfoToYoungPerson: 'FaqAnswerHowToSendGameInfoToYoungPerson',
  FaqAnswerHowToSendGameInfoToYoungPersonMustProvideGamePack: 'FaqAnswerHowToSendGameInfoToYoungPersonMustProvideGamePack',
  FaqAnswerIfGuardianForgetsGameKey: 'FaqAnswerIfGuardianForgetsGameKey',
  FaqAnswerIntroduceGameToAYoungPersonCots: 'FaqAnswerIntroduceGameToAYoungPersonCots',
  FaqAnswerIntroduceGameToAYoungPersonLn: 'FaqAnswerIntroduceGameToAYoungPersonLn',
  FaqAnswerIntroduceGameToAYoungPersonLnAskOpenQuestions: 'FaqAnswerIntroduceGameToAYoungPersonLnAskOpenQuestions',
  FaqAnswerIntroduceGameToAYoungPersonLnDescribeHowGameWorks: 'FaqAnswerIntroduceGameToAYoungPersonLnDescribeHowGameWorks',
  FaqAnswerIntroduceGameToAYoungPersonLnGuardianPrefersToIntroduce: 'FaqAnswerIntroduceGameToAYoungPersonLnGuardianPrefersToIntroduce',
  FaqAnswerIntroduceGameToAYoungPersonLnMakeItFun: 'FaqAnswerIntroduceGameToAYoungPersonLnMakeItFun',
  FaqAnswerIntroduceGameToAYoungPersonLnNormaliseAnxiety: 'FaqAnswerIntroduceGameToAYoungPersonLnNormaliseAnxiety',
  FaqAnswerIntroduceGameToAYoungPersonLnSingledOut: 'FaqAnswerIntroduceGameToAYoungPersonLnSingledOut',
  FaqAnswerMissedTraining: 'FaqAnswerMissedTraining',
  FaqAnswerMissedTrainingCots: 'FaqAnswerMissedTrainingCots',
  FaqAnswerMissedTrainingLn: 'FaqAnswerMissedTrainingLn',
  FaqAnswerMissedTrainingSolution: 'FaqAnswerMissedTrainingSolution',
  FaqAnswerMissedTrainingSolutionLink: 'FaqAnswerMissedTrainingSolutionLink',
  FaqAnswerOngoingSupport: 'FaqAnswerOngoingSupport',
  FaqAnswerOngoingSupportCheckInWithPlayers: 'FaqAnswerOngoingSupportCheckInWithPlayers',
  FaqAnswerOngoingSupportIncludes: 'FaqAnswerOngoingSupportIncludes',
  FaqAnswerOngoingSupportOrganiseMeetings: 'FaqAnswerOngoingSupportOrganiseMeetings',
  FaqAnswerOngoingSupportSendReminders: 'FaqAnswerOngoingSupportSendReminders',
  FaqAnswerReviewTrainingCots: 'FaqAnswerReviewTrainingCots',
  FaqAnswerReviewTrainingLn: 'FaqAnswerReviewTrainingLn',
  FaqAnswerRunOutOfLicenses: 'FaqAnswerRunOutOfLicenses',
  FaqAnswerSendInformationToGuardians: 'FaqAnswerSendInformationToGuardians',
  FaqAnswerSendInformationToGuardiansContactIsAutomated: 'FaqAnswerSendInformationToGuardiansContactIsAutomated',
  FaqAnswerSendInformationToGuardiansSavingNewUserProfile: 'FaqAnswerSendInformationToGuardiansSavingNewUserProfile',
  FaqAnswerSendInformationToGuardiansSendingDownloadLink: 'FaqAnswerSendInformationToGuardiansSendingDownloadLink',
  FaqAnswerSignsOfAnxiety: 'FaqAnswerSignsOfAnxiety',
  FaqAnswerSignsOfAnxietyBehaviours: 'FaqAnswerSignsOfAnxietyBehaviours',
  FaqAnswerSignsOfAnxietyFeelings: 'FaqAnswerSignsOfAnxietyFeelings',
  FaqAnswerSignsOfAnxietyThoughts: 'FaqAnswerSignsOfAnxietyThoughts',
  FaqAnswerWhatIsAnxiety: 'FaqAnswerWhatIsAnxiety',
  FaqAnswerWhatIsAnxietySeparationAnxiety: 'FaqAnswerWhatIsAnxietySeparationAnxiety',
  FaqAnswerWhatIsAnxietySocialAnxiety: 'FaqAnswerWhatIsAnxietySocialAnxiety',
  FaqAnswerWhatIsAnxietySpecificPhobias: 'FaqAnswerWhatIsAnxietySpecificPhobias',
  FaqAnswerWhatIsAnxietyTypesIncluded: 'FaqAnswerWhatIsAnxietyTypesIncluded',
  FaqAnswerWhenCanISeeResults: 'FaqAnswerWhenCanISeeResults',
  FaqQuestionContact: 'FaqQuestionContact',
  FaqQuestionForgotGameKey: 'FaqQuestionForgotGameKey',
  FaqQuestionHowDoIChooseGamePlayers: 'FaqQuestionHowDoIChooseGamePlayers',
  FaqQuestionHowToIntroduceAGuardian: ' FaqQuestionHowToIntroduceAGuardian',
  FaqQuestionHowToSendGameInfoToYoungPerson: 'FaqQuestionHowToSendGameInfoToYoungPerson',
  FaqQuestionIfGuardianForgetsGameKey: 'FaqQuestionIfGuardianForgetsGameKey',
  FaqQuestionIntroduceGameToAYoungPerson: 'FaqQuestionIntroduceGameToAYoungPerson',
  FaqQuestionMissedTraining: 'FaqQuestionMissedTraining',
  FaqQuestionOngoingSupport: 'FaqQuestionOngoingSupport',
  FaqQuestionReviewTraining: 'FaqQuestionReviewTraining',
  FaqQuestionRunOutOfLicenses: 'FaqQuestionRunOutOfLicenses',
  FaqQuestionSendInformationToGuardians: 'FaqQuestionSendInformationToGuardians',
  FaqQuestionSignsOfAnxiety: 'FaqQuestionSignsOfAnxiety',
  FaqQuestionWhatIsAnxiety: 'FaqQuestionWhatIsAnxiety',
  FaqQuestionWhenCanISeeResults: 'FaqQuestionWhenCanISeeResults',
  HoursAndMinutesComponent: 'HoursAndMinutesComponent',
  Label2FATextField: 'Label2FATextField',
  Label2FAButton: 'Label2FAButton',
  Label2FAEmailSent: 'Label2FAEmailSent',
  Label2FAResend: 'Label2FAResend',
  Label2FAResendHelp: 'Label2FAResendHelp',
  LabelADenseTable: 'LabelADenseTable',
  LabelAccountOfCurrentUser: 'LabelAccountOfCurrentUser',
  LabelActive: 'LabelActive',
  LabelActivePlayersThisWeek: 'LabelActivePlayersThisWeek',
  LabelActiveUntil: 'LabelActiveUntil',
  LabelAddress: 'LabelAddress',
  LabelAlreadyHaveAnAccount: 'LabelAlreadyHaveAnAccount',
  LabelAlways: 'LabelAlways',
  LabelAssignSelfSignUpDistributor: 'LabelAssignSelfSignUpDistributor',
  LabelAssignedKeys: 'LabelAssignedKeys',
  LabelAttempted: 'LabelAttempted',
  LabelAverageSessionTime: 'LabelAverageSessionTime',
  LabelAxisMonth: 'LabelAxisMonth',
  LabelBaseline: 'LabelBaseline',
  LabelBeforeChallenge: 'LabelBeforeChallenge',
  LabelBuyerEmail: 'LabelBuyerEmail',
  LabelBuyerName: 'LabelBuyerName',
  LabelCancel: 'LabelCancel',
  LabelChallenge: 'LabelChallenge',
  LabelChallengesCompleted: 'LabelChallengesCompleted',
  LabelChampionsOfTheShengha: 'LabelChampionsOfTheShengha',
  LabelCheckYourPostcode: 'LabelCheckYourPostcode',
  LabelChildCorsCompletedOn: 'LabelChildCorsCompletedOn',
  LabelClickToDownloadResources: 'LabelClickToDownloadResources',
  LabelColonAddress: 'LabelColonAddress',
  LabelColonEmail: 'LabelColonEmail',
  LabelColonName: 'LabelColonName',
  LabelColonPhone: 'LabelColonPhone',
  LabelConfirmPassword: 'LabelConfirmPassword',
  LabelConfirmSendSms: 'LabelConfirmSendSms',
  LabelContact: 'LabelContact',
  LabelContactUs: 'LabelContactUs',
  LabelCorsBaselineCompletedOn: 'LabelCorsBaselineCompletedOn',
  LabelCorsCompletedOn: 'LabelCorsCompletedOn',
  LabelCotsGameKeys: 'LabelCotsGameKeys',
  LabelCotsProLicenses: 'LabelCotsProLicenses',
  LabelCotsProLicensesValidUntil: 'LabelCotsProLicensesValidUntil',
  LabelCountry: 'LabelCountry',
  LabelCreateYoungProfile: 'LabelCreateYoungProfile',
  LabelCurrentMonth: 'LabelCurrentMonth',
  LabelCustomSupportInfo: 'LabelCustomSupportInfo',
  LabelCustomTitle: 'LabelCustomTitle',
  LabelCustomizeSignUpPageUrl: 'LabelCustomizeSignUpPageUrl',
  LabelDate: 'LabelDate',
  LabelDateChallengeAttempted: 'LabelDateChallengeAttempted',
  LabelDay: 'LabelDay',
  LabelDelete: 'LabelDelete',
  LabelDisabilityEnquiry: 'LabelDisabilityEnquiry',
  LabelDisabilityEnquiryTypes: 'LabelDisabilityEnquiryTypes',
  LabelDisabilityLearningDisability: 'LabelDisabilityLearningDisability',
  LabelDisabilityADHD: 'LabelDisabilityADHD',
  LabelDisabilityAutism: 'LabelDisabilityAutism',
  LabelDisabilityPhysicalDisability: 'LabelDisabilityPhysicalDisability',
  LabelDisabilityOther: 'LabelDisabilityOther',
  LabelDisabilityOtherPlaceholder: 'LabelDisabilityOtherPlaceholder',
  LabelDisabilityPreferNotToSay: 'LabelDisabilityPreferNotToSay',
  LabelDistributorAddress: 'LabelDistributorAddress',
  LabelDistributorEmail: 'LabelDistributorEmail',
  LabelDistributorFirstName: 'LabelDistributorFirstName',
  LabelDistributorLastName: 'LabelDistributorLastName',
  LabelDistributorName: 'LabelDistributorName',
  LabelDistributorPhone: 'LabelDistributorPhone',
  LabelDownloadDistributors: 'LabelDownloadDistributors',
  LabelDownloadYoungPeople: 'LabelDownloadYoungPeople',
  LabelDownloadAllYoungPeople: 'LabelDownloadAllYoungPeople',
  LabelDuels: 'LabelDuels',
  LabelDuringChallenge: 'LabelDuringChallenge',
  LabelEditYoungProfile: 'LabelEditYoungProfile',
  LabelEmail: 'LabelEmail',
  LabelEmailAddress: 'LabelEmailAddress',
  LabelEnableCustomSupportInfo: 'LabelEnableCustomSupportInfo',
  LabelEnableSelfSignUp: 'LabelEnableSelfSignUp',
  LabelEnableWebhook: 'LabelEnableWebhook',
  LabelEnterYourPostcode: 'LabelEnterYourPostcode',
  LabelEntityAddress: 'LabelEntityAddress',
  LabelEntityLogo: 'LabelEntityLogo',
  LabelEntityName: 'LabelEntityName',
  LabelEthnicity: 'LabelEthnicity',

  LabelEthnicityArab: 'LabelEthnicityArab',
  LabelEthnicityAsianBritish: 'LabelEthnicityAsianBritish',
  LabelEthnicityBlackEthnicities: 'LabelEthnicityBlackEthnicities',
  LabelEthnicityChinese: 'LabelEthnicityChinese',
  LabelEthnicityMultipleEthnicGroups: 'LabelEthnicityMultipleEthnicGroups',
  LabelEthnicityOtherEthnicGroup: 'LabelEthnicityOtherEthnicGroup',
  LabelEthnicityWhite: 'LabelEthnicityWhite',
  LabelEthnicityWhiteBritish: 'LabelEthnicityWhiteBritish',
  LabelEthnicityWhiteIrish: 'LabelEthnicityWhiteIrish',
  LabelEthnicityWhiteOther: 'LabelEthnicityWhiteOther',
  LabelEthnicityMixedWhiteAndCaribbean: 'LabelEthnicityMixedWhiteAndCaribbean',
  LabelEthnicityMixedWhiteAndAfrican: 'LabelEthnicityMixedWhiteAndAfrican',
  LabelEthnicityMixedWhiteAndAsian: 'LabelEthnicityMixedWhiteAndAsian',
  LabelEthnicityMixedOther: 'LabelEthnicityMixedOther',
  LabelEthnicityAsianIndian: 'LabelEthnicityAsianIndian',
  LabelEthnicityAsianPakistani: 'LabelEthnicityAsianPakistani',
  LabelEthnicityAsianBangladeshi: 'LabelEthnicityAsianBangladeshi',
  LabelEthnicityAsianOther: 'LabelEthnicityAsianOther',
  LabelEthnicityBlackCaribbean: 'LabelEthnicityBlackCaribbean',
  LLabelEthnicityBlackAfrican: 'LLabelEthnicityBlackAfrican',
  LLabelEthnicityBlackOther: 'LLabelEthnicityBlackOther',
  LabelEthnicityOtherChinese: 'LabelEthnicityOtherChinese',
  LabelEthnicityOther: 'LabelEthnicityOther',
  LabelExposureChallengeReflections: 'LabelExposureChallengeReflections',
  LabelFaqFindEverythingYouNeedCots: 'LabelFaqFindEverythingYouNeedCots',
  LabelFaqFindEverythingYouNeedLn: 'LabelFaqFindEverythingYouNeedLn',
  LabelFemale: 'LabelFemale',
  LabelFilterBy: 'LabelFilterBy',
  LabelFinalScore: 'LabelFinalScore',
  LabelForMoreInformationContact: 'LabelForMoreInformationContact',
  LabelGameProgressInfo: 'LabelGameProgressInfo',
  LabelGameSessionTimeInHours: 'LabelGameSessionTimeInHours',
  LabelGameSessionTimeInMins: 'LabelGameSessionTimeInMins',
  LabelGboScore: 'LabelGboScore',
  LabelGender: 'LabelGender',
  LabelGoalName: 'LabelGoalName',
  LabelGoalStartedOn: 'LabelGoalStartedOn',
  LabelGoalProgressEndedOn: 'LabelGoalProgressEndedOn',
  LabelGuardianEmail: 'LabelGuardianEmail',
  LabelGuardianFirstName: 'LabelGuardianFirstName',
  LabelGuardianLastName: 'LabelGuardianLastName',
  LabelGuardianMobile: 'LabelGuardianMobile',
  LabelGuardianPostcode: 'LabelGuardianPostcode',
  LabelGuardianSurveyScasSCore: 'LabelGuardianSurveyScasScore',
  LabelGuardianSurveyScasScore: 'LabelGuardianSurveyScasScore',
  LabelHours: 'LabelHours',
  LabelHoursAndMinutes: 'LabelHoursAndMinutes',
  LabelHrvInMs: 'LabelHrvInMs',
  LabelIfAskedToDoItAgain: 'LabelIfAskedToDoItAgain',
  LabelImproving: 'LabelImproving',
  LabelInGame: 'LabelInGame',
  LabelInstructionsForUse: 'LabelInstructionsForUse',
  LabelInsufficientData: 'LabelInsufficientData',
  LabelInviteYoungPerson: 'LabelInviteYoungPerson',
  LabelInvitesSent: 'LabelInvitesSent',
  LabelInvitesYouHaveSent: 'LabelInvitesYouHaveSent',
  LabelLastLoggedIn: 'LabelLastLoggedIn',
  LabelLearnMore: 'LabelLearnMore',
  LabelLicencesAllocated: 'LabelLicencesAllocated',
  LabelLicenseExpiryDate: 'LabelLicenseExpiryDate',
  LabelLicensesProvidedBy: 'LabelLicencesProvidedBy',
  LabelLicensesUsed: 'LabelLicensesUsed',
  LabelLicensesUsedByYou: 'LabelLicensesUsedByYou',
  LabelLogInHere: 'LabelLogInHere',
  LabelLumiNova: 'LabelLumiNova',
  LabelLumiNovaGameKeys: 'LabelLumiNovaGameKeys',
  LabelLumiNovaLicenses: 'LabelLumiNovaLicenses',
  LabelLumiNovaLicensesValidUntil: 'LabelLumiNovaLicensesValidUntil',
  LabelMarketingVisibility: 'LabelMarketingVisibility',
  LabelMarketingVisibilityAdvertisement: 'LabelMarketingVisibilityAdvertisement',
  LabelMarketingVisibilityCharityCommunity: 'LabelMarketingVisibilityCharityCommunity',
  LabelMarketingVisibilityGpHealthcare: 'LabelMarketingVisibilityGpHealthcare',
  LabelMarketingVisibilityMentalHealthPro: 'LabelMarketingVisibilityMentalHealthPro',
  LabelMarketingVisibilityNewsMedia: 'LabelMarketingVisibilityNewsMedia',
  LabelMarketingVisibilitySchool: 'LabelMarketingVisibilitySchool',
  LabelMarketingVisibilitySearchEngine: 'LabelMarketingVisibilitySearchEngine',
  LabelMarketingVisibilitySocialMedia: 'LabelMarketingVisibilitySocialMedia',
  LabelMarketingVisibilityFamilyFriend: 'LabelMarketingVisibilityFamilyFriend',
  LabelMarketingVisibilityOther: 'LabelMarketingVisibilityOther',
  LabelMale: 'LabelMale',
  LabelMean: 'LabelMean',
  LabelMeanOfMean: 'LabelMeanOfMean',
  LabelMeanOfMedian: 'LabelMeanOfMedian',
  LabelMedian: 'LabelMedian',
  LabelMedianOfMean: 'LabelMedianOfMean',
  LabelMedianOfMedian: 'LabelMedianOfMedian',
  LabelMenu: 'LabelMenu',
  LabelMHSDSData: 'LabelMHSDSData',
  LabelMHSDSSettings: 'LabelMHSDSSettings',
  LabelMins: 'LabelMins',
  LabelMinutes: 'LabelMinutes',
  LabelMobile: 'LabelMobile',
  LabelMobileNumber: 'LabelMobileNumber',
  LabelMonth: 'LabelMonth',
  LabelNA: 'LabelNA',
  LabelName: 'LabelName',
  LabelNever: 'LabelNever',
  LabelNextSteps: 'LabelNextSteps',
  LabelNonBinary: 'LabelNonBinary',
  LabelNone: 'LabelNone',
  LabelNotImproving: 'LabelNotImproving',
  LabelNumberOfYoungPeoplePlaying: 'LabelNumberOfYoungPeoplePlaying',
  LabelOften: 'LabelOften',
  LabelOk: 'LabelOk',
  LabelOrganisationAddress: 'LabelOrganisationAddress',
  LabelOrganisationEnableMHSDSForAdminDownload: 'LabelOrganisationEnableMHSDSForAdminDownload',
  LabelOrganisationEnableMHSDS: 'LabelOrganisationEnableMHSDS',
  LabelOrganisationODSCode: 'LabelOrganisationODSCode',
  LabelOrganisationOrgIdComm: 'OrgIdComm',
  LabelOrganisationName: 'LabelOrganisationName',
  LabelOrganisationPhoneNumber: 'LabelOrganisationPhoneNumber',
  LabelOrganisationPostcode: 'LabelOrganisationPostcode',
  LabelOrganisationSettings: 'LabelOrganisationSettings',
  LabelOther: 'LabelOther',
  LabelOtherNotListed: 'LabelOtherNotListed',
  LabelOutOfGame: 'LabelOutOfGame',
  LabelParent: 'LabelParent',
  LabelParentGuardianInformation: 'LabelParentGuardianInformation',
  LabelPassword: 'LabelPassword',
  LabelPerformanceOfAllYoungPeopleOverFirst20: 'LabelPerformanceOfAllYoungPeopleOverFirst20',
  LabelPerformanceOfAllYoungPeopleOverLast20: 'LabelPerformanceOfAllYoungPeopleOverLast20',
  LabelPerformanceOverFirst20: 'LabelPerformanceOverFirst20',
  LabelPerformanceOverLast20: 'LabelPerformanceOverLast20',
  LabelPhone: 'LabelPhone',
  LabelPlayerPerformance: 'LabelPlayerPerformance',
  LabelPlayersBestTime: 'LabelPlayersBestTime',
  LabelPlayersGameUsage: 'LabelPlayersGameUsage',
  LabelPleaseVisitOfficialLumiNova: 'LabelPleaseVisitOfficialLumiNova',
  LabelPointPersonEmail: 'LabelPointPersonEmail',
  LabelPointPersonName: 'LabelPointPersonName',
  LabelPointPersonPhone: 'LabelPointPersonPhone',
  LabelPostcode: 'LabelPostcode',
  LabelPreviousMonth: 'LabelPreviousMonth',
  LabelPrivacyPolicy: 'LabelPrivacyPolicy',
  LabelPrivacyPolicySelfSignup: 'LabelPrivacyPolicySelfSignup',
  LabelProvidePhoneNumberForGameProgress: 'LabelProvidePhoneNumberForGameProgress',
  LabelReason: 'LabelReason',
  LabelReasonColon: 'LabelReasonColon',
  LabelRelationship: 'LabelRelationship',
  LabelRelationshipToYoungPerson: 'LabelRelationshipToYoungPerson',
  LabelRepeated: 'Repeated',
  LabelResponse: 'LabelResponse',
  LabelScaleTenExplanation: 'LabelScaleTenExplanation',
  LabelScaleZeroExplanation: 'LabelScaleZeroExplanation',
  LabelScore: 'LabelScore',
  LabelSecondaryCheckbox: 'LabelSecondaryCheckbox',
  LabelSelectEllipses: 'LabelSelectEllipses',
  LabelSelfSignUpSettings: 'LabelSelfSignUpSettings',
  LabelServiceUserRefDetailed: 'LabelServiceUserRefDetailed',
  LabelServiceUserReference: 'LabelServiceUserReference',
  LabelServiceUserReferenceField: 'LabelServiceUserReferenceField',
  LabelShowServiceUserReference: 'LabelShowServiceUserReference',
  LabelCustomDropdownTitle: 'LabelCustomDropdownTitle',
  LabelCustomDropdownLabel: 'LabelCustomDropdownLabel',
  LabelDropdownCustomEntry: 'LabelDropdownCustomEntry',
  LabelCustomEntryOne: 'LabelCustomEntryOne',
  LabelShowCustomEntryOne: 'LabelShowCustomEntryOne',
  LabelCustomEntryTwo: 'LabelCustomEntryTwo',
  LabelShowCustomEntryTwo: 'LabelShowCustomEntryTwo',
  LabelCustomEntryThree: 'LabelCustomEntryThree',
  LabelShowCustomEntryThree: 'LabelShowCustomEntryThree',
  LabelMarketingVisibilityCheckboxTitle: 'LabelMarketingVisibilityCheckboxTitle',
  LabelMarketingVisibilityRow: 'LabelMarketingVisibilityRow',
  LabelSelfSignupDisabled: 'LabelSelfSignupDisabled',
  LabelSmsToGuardian: 'LabelSmsToGuardian',
  LabelSMSDataNextSMS: 'LabelSMSDataNextSMS',
  LabelSMSDataPreviousSMS: 'LabelSMSDataPreviousSMS',
  LabelSMSDataResendCORSSMS: 'LabelSMSDataResendCORSSMS',
  LabelSMSDataCORSURL: 'LabelSMSDataCORSURL',
  LabelSMSDataResendCORSSMSMessage: 'LabelSMSDataResendCORSSMSMessage',
  LabelSMSDataResendCORSSMSConfirmation: 'LabelSMSDataResendCORSSMSConfirmation',
  LabelSMSDataURLCopy: 'LabelSMSDataURLCopy',
  LabelSMSDataURLCopied: 'LabelSMSDataURLCopied',
  LabelSMSNoneScheduled: 'LabelSMSNoneScheduled',
  LabelSMSNoneSent: 'LabelSMSNoneScheduled',
  LabelSometimes: 'LabelSometimes',
  LabelSteadyAverage: 'LabelSteadyAverage',
  LabelSupport: 'LabelSupport',
  LabelSurveyName: 'LabelSurveyName',
  LabelTelephone: 'LabelTelephone',
  LabelTermsAndConditions: 'LabelTermsAndConditions',
  LabelThanks: 'LabelThanks',
  LabelTickIfTestAccount: 'LabelTickIfTestAccount',
  LabelTickTermsAndConditionsIAgreeTo: 'LabelTickTermsAndConditionsIAgreeTo',
  LabelTickTermsAndConditionsIHaveReadAndUnderstand: 'LabelTickTermsAndConditionsIHaveReadAndUnderstand',
  LabelTime: 'LabelTime',
  LabelTimeAboveAverage: 'LabelTimeAboveAverage',
  LabelTimeBelowAverage: 'LabelTimeBelowAverage',
  LabelTimeSpent: 'LabelTimeSpent',
  LabelToViewMoreAttemptsInstructions: 'LabelToViewMoreAttemptsInstructions',
  LabelTotal: 'LabelTotal',
  LabelTotalDeactivated: 'LabelTotalDeactivated',
  LabelTotalDuels: 'LabelTotalDuels',
  LabelTotalInvitesSent: 'LabelTotalInvitesSent',
  LabelTotalLicensesAllocatedTo: 'LabelTotalLicensesAllocatedTo',
  LabelTotalLicensesAvailable: 'LabelTotalLicensesAvailable',
  LabelTotalLicensesUsed: 'LabelTotalLicensesUsed',
  LabelTotalNumberOfDuels: 'LabelTotalNumberOfDuels',
  LabelTotalStartedPlaying: 'LabelTotalStartedPlaying',
  LabelTotalSurveyScore: 'LabelTotalSurveyScore',
  LabelTotalTimePlaying: 'LabelTotalTimePlaying',
  LabelUploadDistributors: 'LabelUploadDistributors',
  LabelUrl: 'LabelUrl',
  LabelUsage: 'LabelUsage',
  LabelUsers: 'LabelUsers',
  LabelWebhookSettings: 'LabelWebhookSettings',
  LabelWebhookUrl: 'LabelWebhookUrl',
  LabelWorkAddress: 'LabelWorkAddress',
  LabelWorkPhone: 'LabelWorkPhone',
  LabelWorryScaleScore: 'LabelWorryScaleScore',
  LabelYoungFirstName: 'LabelYoungFirstName',
  LabelYoungLastName: 'LabelYoungLastName',
  LabelYoungPeoplePlayingTheGame: 'LabelYoungPeoplePlayingTheGame',
  LabelYoungPeopleNotYetStartedTab: 'LabelYoungPeopleNotYetStartedTab',
  LabelYoungPeoplePlayingTheGameTab: 'LabelYoungPeoplePlayingTheGameTab',
  LabelYoungPeoplePlayingLumiNova: 'LabelYoungPeoplePlayingLumiNova',
  LabelYoungPeopleDeactivatedTab: 'abelYoungPeopleDeactivatedTab',
  LabelYoungPeopleWhoPlayed: 'LabelYoungPeopleWhoPlayed',
  LabelYoungPeopleWorriedDuringChallenges: 'LabelYoungPeopleWorriedDuringChallenges',
  LabelYoungPersonDOB: 'LabelYoungPersonDOB',
  LabelYourInformationIsSecure: 'LabelYourInformationIsSecure',
  LinkForgotPassword: 'LinkForgotPassword',
  LinkNeedSupport: 'LinkNeedSupport',
  ListCorsEverything: 'ListCorsEverything',
  ListCorsFamily: 'ListCorsFamily',
  ListCorsMe: 'ListCorsMe',
  ListCorsSchool: 'ListCorsSchool',
  MessageConfirmDeleteEntity: 'MessageConfirmDeleteEntity',
  MessageDistributorsWillAlsoBeRemoved: 'MessageDistributorsWillAlsoBeRemoved',
  MessageProfileCreated: 'MessageProfileCreated',
  MessageWithDownloadLinkSent: 'MessageWithDownloadLinkSent',
  NavbarAdmins: 'NavbarAdmins',
  NavbarChampionsOfShenghaAggregateData: 'NavbarChampionsOfShenghaAggregateData',
  NavbarDashboard: 'NavbarDashboard',
  NavbarFAQs: 'NavbarFAQs',
  NavbarHowItWorks: 'NavbarHowItWorks',
  NavbarLumiNovaAggregateData: 'NavbarLumiNovaAggregateData',
  NavbarMonthlyReports: 'NavbarMonthlyReports',
  NavbarOrganisations: 'NavbarOrganisations',
  NavbarResources: 'NavbarResources',
  NavbarYoungPeople: 'NavbarYoungPeople',
  ScaleZeroToTen: 'ScaleZeroToTen',
  Settings: 'Settings',
  SignIn: 'SignIn',
  TabActive: 'TabActive',
  TabDeactivated: 'TabDeactivated',
  TabDeleted: 'TabDeleted',
  TabInvited: 'TabInvited',
  TextAllDone: 'TextAllDone',
  TextCheckForLumiNovaDownloadLink: 'TextCheckForLumiNovaDownloadLink',
  TextCorsInformationOne: 'TextCorsInformationOne',
  TextCorsInformationThree: 'TextCorsInformationThree',
  TextCorsInformationTwo: 'TextCorsInformationTwo',
  TextCutOffScoresInformation: 'TextCutOffScoresInformation',
  TextEnterEmailForPasswordReset: 'TextEnterEmailForPasswordReset',
  TextGboInformationOne: 'TextGboInformationOne',
  TextGboInformationTwo: 'TextGboInformationTwo',
  TextHubDownForMaintenance: 'TextHubDownForMaintenance',
  TextLumiNovaHasBeenProvidedBy: 'TextLumiNovaHasBeenProvidedBy',
  TextMaintenanceLength: 'TextMaintenanceLength',
  Title2FAHeading: 'Title2FAHeading',
  Title2FADescription: 'Title2FADescription',
  TitleAchieved: 'TitleAchieved',
  TitleActive: 'TitleActive',
  TitleAddress: 'TitleAddress',
  TitleAssignSelfSignUpDistributor: 'TitleAssignSelfSignUpDistributor',
  TitleBack: 'TitleBack',
  TitleBackOnlineSoon: 'TitleBackOnlineSoon',
  TitleBestWeeksTotalTime: 'TitleBestWeeksTotalTime',
  TitleBreakdownByDistributor: 'TitleBreakdownByDistributor',
  TitleBreakdownByEntity: 'TitleBreakdownByEntity',
  TitleChallenges: 'TitleChallenges',
  TitleChampionsOfTheShengha: 'TitleChampionsOfTheShengha',
  TitleChooseAnIntervention: 'TitleChooseAnIntervention',
  TitleClosenessToGoal: 'TitleClosenessToGoal',
  TitleCompleted: 'TitleCompleted',
  TitleCompletedSurveysColon: 'TitleCompletedSurveysColon',
  TitleConfirmYoungProfile: 'TitleConfirmYoungProfile',
  TitleCors: 'TitleCors',
  TitleCorsInformation: 'TitleCorsInformation',
  TitleCorsResult: 'TitleCorsResult',
  TitleCotsGame: 'TitleCotsGame',
  TitleCotsKeysAvailable: 'TitleCotsKeysAvailable',
  TitleCreateADistributor: 'TitleCreateADistributor',
  TitleCreateAnOrganisation: 'TitleCreateAnOrganisation',
  TitleCreateEntity: 'TitleCreateEntity',
  TitleCreateYourAccount: 'TitleCreateYourAccount',
  TitleCreated: 'TitleCreated',
  TitleCreatedAt: 'TitleCreatedAt',
  TitleCustomSupportInfo: 'TitleCustomSupportInfo',
  TitleCustomTitle: 'TitleCustomTitle',
  TitleCustomizeSignUpPageUrl: 'TitleCustomizeSignUpPageUrl',
  TitleCutOffForCarerCorsReport: 'TitleCutOffForCarerCorsReport',
  TitleCutOffScores: 'TitleCutOffScores',
  TitleDashboard: 'TitleDashboard',
  TitleDeactivateGameKey: 'TitleDeactivateGameKey',
  TitleDeactivatedOn: 'TitleDeactivatedOn',
  TitleDeleteYoungPerson: 'TitleDeleteYoungPerson',
  TitleDistributor: 'TitleDistributor',
  TitleDistributorAdmin: 'TitleDistributorAdmin',
  TitleDistributorAdminSignIn: 'TitleDistributorAdminSignIn',
  TitleEditDistributorName: 'TitleEditDistributorName',
  TitleEditEntity: 'TitleEditEntity',
  TitleEditOrganisationName: 'TitleEditOrganisationName',
  TitleEmail: 'TitleEmail',
  TitleEnableCustomSupportInfo: 'TitleEnableCustomSupportInfo',
  TitleEnableSelfSignUp: 'TitleEnableSelfSignUp',
  TitleEnableWebhook: 'TitleEnableWebhook',
  TitleEntity: 'TitleEntity',
  TitleEntityLogo: 'TitleEntityLogo',
  TitleEntitySettings: 'TitleEntitySettings',
  TitleError: 'TitleError',
  TitleFaq: 'TitleFaq',
  TitleFaqCannotFindWhatYouAreLookingFor: 'TitleFaqCannotFindWhatYouAreLookingFor',
  TitleFaqChoosingGamePlayers: 'TitleFaqChoosingGamePlayers',
  TitleFaqGameMaintenance: 'TitleFaqGameMaintenance',
  TitleFaqSupportingGamePlayers: 'TitleFaqSupportingGamePlayers',
  TitleFaqSupportingGamePlayersAndGuardians: 'TitleFaqSupportingGamePlayersAndGuardians',
  TitleFaqTraining: 'TitleFaqTraining',
  TitleFileSize: 'TitleFileSize',
  TitleFilename: 'TitleFilename',
  TitleFinalScore: 'TitleFinalScore',
  TitleForMindfulBreathing: 'TitleForMindfulBreathing',
  TitleGameKey: 'TitleGameKey',
  TitleGameStatus: 'TitleGameStatus',
  TitleGameTimeThisWeek: 'TitleGameTimeThisWeek',
  TitleGboInformation: 'TitleGboInformation',
  TitleGoBack: 'TitleGoBack',
  TitleGoalBasedOutcomes: 'TitleGoalBasedOutcomes',
  TitleGoals: 'TitleGoals',
  TitleGuardianAnxietySurveyResults: 'TitleGuardianAnxietySurveyResults',
  TitleGuardianName: 'TitleGuardianName',
  TitleGuardianRelationship: 'TitleGuardianRelationship',
  TitleInactive: 'TitleInactive',
  TitleInactiveUsersThisWeek: 'TitleInactiveUsersThisWeek',
  TitleInviteDistributor: 'TitleInviteDistributor',
  TitleInvited: 'TitleInvited',
  TitleInvitedAt: 'TitleInvitedAt',
  TitleInvitedOn: 'TitleInvitedOn',
  TitleInviteeEmail: 'TitleInviteeEmail',
  TitleInviteeLink: 'TitleInviteeLink',
  TitleInviteeName: 'TitleInviteeName',
  TitleKey: 'TitleKey',
  TitleLastModified: 'TitleLastModified',
  TitleLastSeen: 'TitleLastSeen',
  TitleLastUsedDate: 'TitleLastUsedDate',
  TitleLicencesAllocated: 'TitleLicencesAllocated',
  TitleLicensesUsed: 'TitleLicensesUsed',
  TitleLumiNova: 'TitleLumiNova',
  TitleLumiNovaAccessFormGreatNews: 'TitleLumiNovaAccessFormGreatNews',
  TitleLumiNovaAccessForm: 'TitleLumiNovaAccessForm',
  TitleLumiNovaDashboardReport: 'TitleLumiNovaDashboardReport',
  TitleLumiNovaKeysAvailable: 'TitleLumiNovaKeysAvailable',
  TitleLumiNovaTalesOfCourage: 'TitleLumiNovaTalesOfCourage',
  TitleLumiNovaYoungReport: 'TitleLumiNovaYoungReport',
  TitleMonthlyReport: 'TitleMonthlyReport',
  TitleMissions: 'TitleMissions',
  TitleName: 'TitleName',
  TitleNotYetActivated: 'TitleNotYetActivated',
  TitleNotYetStarted: 'TitleNotYetStarted',
  TitleOptedOut: 'TitleOptedOut',
  TitleOrganisation: 'TitleOrganisation',
  TitleOrganisationAdmin: 'TitleOrganisationAdmin',
  TitleOrganisationAdminSignIn: 'TitleOrganisationAdminSignIn',
  TitleOrganisationDashboard: 'TitleOrganisationDashboard',
  TitleOverviewForYoungPeople: 'TitleOverViewForYoungPeople',
  TitleOverviewReportForYoungPeople: 'TitleOverviewReportForYoungPeople',
  TitlePhone: 'TitlePhone',
  TitleParentOnboarding: 'TitleParentOnboarding',
  TitlePlayerGameProgress: 'TitlePlayerGameProgress',
  TitlePlayerGameTime: 'TitlePlayerGameTime',
  TitlePlayingTheGame: 'TitlePlayingTheGame',
  TitlePointPerson: 'TitlePointPerson',
  TitlePointPersonEmail: 'TitlePointPersonEmail',
  TitlePointPersonName: 'TitlePointPersonName',
  TitlePointPersonPhone: 'TitlePointPersonPhone',
  TitlePrivacyPolicy: 'TitlePrivacyPolicy',
  TitleReason: 'TitleReason',
  TitleRepeated: 'TitleRepeated',
  TitleResetPassword: 'TitleResetPassword',
  TitleResources: 'TitleResources',
  TitleServiceUserReference: 'TitleServiceUserReference',
  TitleServiceUserReferenceField: 'TitleServiceUserReferenceField',
  TitleSessionsPlayed: 'TitleSessionsPlayed',
  TitleShowServiceUserReference: 'TitleShowServiceUserReference',
  TitleSorryProblem: 'TitleSorryProblem',
  TitleStartedGame: 'TitleStartedGame',
  TitleTableDeactivated: 'TitleTableDeactivated',
  TitleTableDistributors: 'TitleTableDistributors',
  TitleTableEntity: 'TitleTableEntity',
  TitleTableInvitedDate: 'TitleTableInvitedDate',
  TitleTableInvitesSent: 'TitleTableInvitesSent',
  TitleTableLastLoggedIn: 'TitleTableLastLoggedIn',
  TitleTableLicenses: 'TitleTableLicenses',
  TitleTableStartedPlaying: 'TitleTableStartedPlaying',
  TitleTableStartedPlayingDate: 'TitleTableStartedPlayingDate',
  TitleTableYoungPersonName: 'TitleTableYoungPersonName',
  TitleTestAccountsInUse: 'TitleTestAccountsInUse',
  TitleThisWeeksAggregateReport: 'TitleThisWeeksAggregateReport',
  TitleToHelpWorries: 'TitleToHelpWorries',
  TitleTotalGameSessions: 'TitleTotalGameSessions',
  TitleUnnamedCorsResults: 'TitleUnnamedCorsResults',
  TitleUserEmail: 'TitleUserEmail',
  TitleUserName: 'TitleUserName',
  TitleUserUniqueLink: 'TitleUserUniqueLink',
  TitleUsers: 'TitleUsers',
  TitleValidUntil: 'TitleValidUntil',
  TitleVictoryLegendPercentageOfUsers: 'TitleVictoryLegendPercentageOfUsers',
  TitleWebhookSettings: 'TitleWebhookSettings',
  TitleWebhookUrl: 'TitleWebhookUrl',
  TitleWebsite: 'TitleWebsite',
  TitleWelcomePlayer: 'TitleWelcomePlayer',
  TitleYesSubmit: 'PositiveTitleYesSubmit',
  TitleYoungPerson: 'TitleYoungPerson',
  TitleYoungPersonDetails: 'TitleYoungPersonDetails',
  TitleYoungPersonInformation: 'TitleYoungPersonInformation',
  TitleYoungPersonReflections: 'TitleYoungPersonReflections',
  // Restrictions and custom error
  LabelRestrictPostCode: 'LabelRestrictPostCode',
  LabelRestrictSignupCountryTo: 'LabelRestrictSignupCountryTo',
  LabelAcceptPostCodesStartingWith: 'LabelAcceptPostCodesStartingWith',
  LabelAvailabilityText: 'LabelAvailabilityText',
  LabelCustomSupportText: 'LabelCustomSupportText',
  LabelEnableCustomErrorMessage: 'LabelEnableCustomErrorMessage',
  LabelCustomErrorMessage: 'LabelCustomErrorMessage',
  LabelCustomErrorCTAMessage: 'LabelCustomErrorCTAMessage',
  LabelCustomErrorCTAURL: 'LabelCustomErrorCTAURL',
  TitleRestrictionSettings: 'TitleRestrictionSettings',
  SubTitleAvailabilityText: 'SubTitleAvailabilityText',
  SubTitleCustomSupportText: 'SubTitleCustomSupportText',
  SubTitleExamplePatternText:'SubTitleExamplePatternText',
  InvalidPattern: 'InvalidPattern',
  InvalidPostcodeError: 'InvalidPostcodeError',
  LabelCompleted: 'Completed'
}
