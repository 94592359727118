import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Card, Container, FormControl, Grid2 as Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { styled } from '@mui/styles';
import { countryList, helperTextColor } from '../../constants';
import { useViewport } from '../../utilities/viewport';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { axios, catchAxios } from '../../services/networkRequest';
import Error from '../Error';
import { genericAlert, getChain } from '../../services/helpers';
import ExitButton from '../ExitButton';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import { stateToHTML } from 'draft-js-export-html';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledDetailedSection } from '../../StyledComponents';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: '16px',
}));

const FormControlClasses = {
  width: '100%',
  marginTop: '16px',
  textAlign: 'left',
}
const StyledImageClasses = {
  height: '5rem',
  objectFit: 'cover',
  borderRadius: '4px',
  marginLeft: '0.5rem',
  padding: '0.5rem',
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  ...FormControlClasses
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  ...FormControlClasses
}));

const StyledImage = styled('img')(({ theme }) => ({
  ...StyledImageClasses
}));

const StyledImageDiv = styled('div')(({ theme }) => ({
  ...StyledImageClasses
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.4)',
}));


async function updateEntitySettings(axios, values, entityId) {
  return axios.post(`/entity/${ entityId }/settings`, values)
}

async function updateEntitySettingsLogo(axios, logo) {
  const formData = new FormData();

  formData.append('logo', logo);

  return axios.post(`/entity/settings/logo`, formData, {
    headers: {
      ContentType: 'multipart/form-data',
    },
  });
}

function noContentInitialValues(entityName) {

  return ({
    self_sign_up_settings: {
      enable: false,
      logo: undefined,
      url: '',
      distributor_id: undefined,
      guid: undefined,
    },
    webhook_settings: {
      enable: false,
      url: '',
    },
    custom_support_info: {
      enable: false,
      entity_name: entityName || '',
      email: '',
      phone: '',
      website: '',
    },
    service_user_reference_field: {
      show: false,
      custom_title: '',
    },
    custom_entry_one: {
      show: false,
      custom_entry_one_name: '',
      show_dropdown: false,
      custom_dropdown_one_value: '',

    },
    custom_entry_two: {
      show: false,
      custom_entry_two_name: '',
      show_dropdown: false,
      custom_dropdown_two_value: '',

    },
    custom_entry_three: {
      show: false,
      custom_entry_three_name: '',
      show_dropdown: false,
      custom_dropdown_three_value: '',
    },
    lumi_nova_visibility: {
      show: false,
    },
    restrict_postcode_settings: {
      enable: false,
      country: '',
      patterns: undefined,
      availability_text: '',
      custom_support_text: '',
      enable_custom_error_message: false,
      custom_error_message: '',
      custom_error_cta_message: '',
      custom_error_cta_url: ''
    },
  });
}

export default function EntitySettingsPage({ location }) {
  const navigate = useNavigate();
  const params = useParams();

  const [entity, setEntity] = useState(getChain(location, "state", 'entity'));
  const [distributors, setDistributors] = useState(getChain(entity, 'distributors') || [])

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, [inputLabel]);

  const { views: { mobile } } = useViewport();
  const leftColumnWidth = mobile ? 12 : 5;
  const rightColumnWidth = mobile ? 12 : 7;

  const [initialValues, setInitialValues] = useState(noContentInitialValues(getChain(entity, "name")));
  const [logoPromise, setLogoPromise] = useState(Promise.resolve());
  const [logoComplete, setLogoComplete] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if(entity) {
      axios.get(`/entity/${ entity.id }/settings`)
        .then(({ data }) => {
          const {postcode_restriction_settings, ...rest} = data;
          const {postcode_patterns, ...rest2} = postcode_restriction_settings;
          const sanitizedData = {
            ...rest,
            restrict_postcode_settings:{
              ...rest2,
              patterns: postcode_patterns.join(','),
            }
          }
          setInitialValues(sanitizedData);
          const guid = getChain(sanitizedData, "self_sign_up_settings", "guid");
          setLogoPromise(Promise.resolve(guid));
        });
    } else {
      axios.get(`/entity/${ getChain(params, 'ent_id') }`)
        .then(({data}) => {
          setEntity(data);
          setDistributors(data.distributors);
        })
    }
  }, [entity, params, setLogoComplete]);

  useEffect(() =>{
    logoPromise.then(guid => {
      setLogoComplete(!!guid);
    });
  }, [logoPromise, setLogoComplete]);

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } path={`/organisation/${getChain(params, 'org_id')}/entity/${getChain(params, 'ent_id')}`} confirmation={ callback => {
        genericAlert(t(translationKey.AlertTitleCancelEntityForm), t(translationKey.AlertBodyCancelEntityForm), callback);
      } } />

      <Container component="main" maxWidth="md">
        <Typography component="h1" variant="h5">
          {t(translationKey.TitleEntitySettings, { entityName: (getChain(entity,'name') || "Entity" )})}
        </Typography>
        <Formik
          enableReinitialize={ true }
          initialValues={ initialValues }
          validate={ (values) => {
            const errors = {};
            const { self_sign_up_settings, webhook_settings } = values;

            if(self_sign_up_settings.enable && !self_sign_up_settings.url) {
              errors.url = t(translationKey.ErrorUrlIncomplete);
            }

            // If self sign up is enabled, form can be submitted if there is an existing guid, or if not a logo
            // file must be provided
            if(self_sign_up_settings.enable && !logoComplete) {
              errors.logo = t(translationKey.ErrorLogoRequired);
            }

            if(self_sign_up_settings.enable && !self_sign_up_settings.distributor_id) {
              errors.distributor_id = t(translationKey.ErrorDistributorMustBeSelected);
            }

            if(webhook_settings.enable && !webhook_settings.url) {
              errors.webhook_url = t(translationKey.ErrorUrlIncomplete);
            }

            return errors;
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const {restrict_postcode_settings, ...rest} = values;
            const sanitized_restrict_postcode_settings = {
              ...restrict_postcode_settings,
              patterns: restrict_postcode_settings.patterns?.trim().split(',')||[],
            }
            const sanitized_values = {
              ...rest,
              restrict_postcode_settings:sanitized_restrict_postcode_settings
            }
            logoPromise.then(guid => updateEntitySettings(axios, {
              ...sanitized_values,
              self_sign_up_settings: { ...sanitized_values.self_sign_up_settings, guid },
            }, entity.id))
              .then(() => {
                navigate(`/organisation/${getChain(params, 'org_id')}/entity/${ entity.id }`);
              })
              .catch(err => {
                catchAxios(setFieldError)(err);
              })
              .finally(() => {
                setSubmitting(false);
              });
          } }
        >
          {
            ({
               values,
               handleChange,
               handleBlur,
               handleSubmit,
               errors,
               isValid,
               isSubmitting
             }) =>
              <form onSubmit={ handleSubmit }>
                
                <FormFieldSelfSignUp values={ values }
                                     handleBlur={ handleBlur }
                                     handleChange={ handleChange }
                                     inputLabel={ inputLabel }
                                     labelWidth={ labelWidth }
                                     leftColumnWidth={ leftColumnWidth }
                                     rightColumnWidth={ rightColumnWidth }
                                     distributors={ distributors }
                                     setLogoPromise={ setLogoPromise }
                                     errors={ errors }
                />

                <FormFieldWebhook values={ values }
                                  handleBlur={ handleBlur }
                                  handleChange={ handleChange }
                                  leftColumnWidth={ leftColumnWidth }
                                  rightColumnWidth={ rightColumnWidth }
                                  errors={ errors }
                />

                <FormFieldCustomSupportInfo values={ values }
                                            handleBlur={ handleBlur }
                                            handleChange={ handleChange }
                                            leftColumnWidth={ leftColumnWidth }
                                            rightColumnWidth={ rightColumnWidth } />

                <FormFieldServiceUserReference values={ values }
                                               handleBlur={ handleBlur }
                                               handleChange={ handleChange }
                                               leftColumnWidth={ leftColumnWidth }
                                               rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_one.custom_entry_one_name}
                                      checkboxName="custom_entry_one.show"
                                      textFieldName="custom_entry_one.custom_entry_one_name"
                                      enabled={values.custom_entry_one.show}
                                      checkboxLabelValue="CustomEntryFieldOneShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryOne)}
                                      dropdownName="custom_entry_one.show_dropdown"
                                      dropdownCheckboxLabelValue="CustomEntryFieldOneShowDropdown"
                                      dropdownCheckboxTitle={t(translationKey.LabelDropdownCustomEntry)}
                                      dropdownEnabled={values.custom_entry_one.show_dropdown}
                                      dropdownFieldName="custom_entry_one.custom_dropdown_one_value"
                                      dropdownValue={values.custom_entry_one.custom_dropdown_one_value}
                                      rowLabel={t(translationKey.LabelCustomEntryOne)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_two.custom_entry_two_name}
                                      checkboxName="custom_entry_two.show"
                                      textFieldName="custom_entry_two.custom_entry_two_name"
                                      enabled={values.custom_entry_two.show}
                                      checkboxLabelValue="CustomEntryFieldTwoShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryTwo)}
                                      dropdownName="custom_entry_two.show_dropdown"
                                      dropdownCheckboxLabelValue="CustomEntryFieldTwoShowDropdown"
                                      dropdownCheckboxTitle={t(translationKey.LabelDropdownCustomEntry)}
                                      dropdownEnabled={values.custom_entry_two.show_dropdown}
                                      dropdownFieldName="custom_entry_two.custom_dropdown_two_value"
                                      dropdownValue={values.custom_entry_two.custom_dropdown_two_value}
                                      rowLabel={t(translationKey.LabelCustomEntryTwo)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_three.custom_entry_three_name}
                                      checkboxName="custom_entry_three.show"
                                      textFieldName="custom_entry_three.custom_entry_three_name"
                                      enabled={values.custom_entry_three.show}
                                      checkboxLabelValue="CustomEntryFieldThreeShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryThree)}
                                      dropdownName="custom_entry_three.show_dropdown"
                                      dropdownCheckboxLabelValue="CustomEntryFieldThreeShowDropdown"
                                      dropdownCheckboxTitle={t(translationKey.LabelDropdownCustomEntry)}
                                      dropdownEnabled={values.custom_entry_three.show_dropdown}
                                      dropdownFieldName="custom_entry_three.custom_dropdown_three_value"
                                      dropdownValue={values.custom_entry_three.custom_dropdown_three_value}
                                      rowLabel={t(translationKey.LabelCustomEntryThree)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldMarketingVisibility leftColumnWidth={leftColumnWidth}
                                              rightColumnWidth={rightColumnWidth}
                                              enabled={values.lumi_nova_visibility.show}
                                              checkboxLabelValue="LumiNovaVisibilityShow"
                                              checkboxTitle={t(translationKey.LabelMarketingVisibilityCheckboxTitle)}
                                              rowLabel={t(translationKey.LabelMarketingVisibilityRow)}
                                              checkboxName="lumi_nova_visibility.show"
                                              handleChange={handleChange}
                />

                <FormFieldRestrictions values={values}
                                       handleBlur={handleBlur}
                                       handleChange={handleChange}
                                       leftColumnWidth={leftColumnWidth}
                                       rightColumnWidth={rightColumnWidth}/>

                <FormFieldCustomErrors values={values}
                                       handleBlur={handleBlur}
                                       handleChange={handleChange}
                                       leftColumnWidth={leftColumnWidth}
                                       rightColumnWidth={rightColumnWidth}/>
                <br/>
                <Error message={ errors['network'] } />
                <br/>

                <Button
                  disabled={ !isValid || isSubmitting }
                  type="submit"
                  variant="contained"
                  style={{ padding: "0.5rem 1rem", background: '#2D9BF0', color: 'white', borderRadius: '4px' }}
                  color="primary">{t(translationKey.ButtonSubmit)}
                  
                </Button>
              </form>
          }
        </Formik>

        <div style={ { marginBottom: '3rem' } } />
      </Container>
    </Card>
  )
}

function FormFieldSelfSignUp({ values: { self_sign_up_settings }, errors, setLogoPromise, handleChange, handleBlur, leftColumnWidth, rightColumnWidth, inputLabel, labelWidth, distributors }) {
  const { t } = useTranslation();

  const guid = getChain(self_sign_up_settings, "guid");
  const [displayImage, setDisplayImage] = useState();

  useEffect(() => {
    setDisplayImage(guid && `${ process.env.REACT_APP_BASE_URL }/entity/logo/${ guid }`);
  }, [guid]);

  const updateLogo = (event) => {
    const logo = event.target.files[0];
    setDisplayImage(URL.createObjectURL(logo));

    const promise = updateEntitySettingsLogo(axios, logo)
      .then(({ data }) => data.guid);

    setLogoPromise(promise);
  }

  const enabled = self_sign_up_settings.enable;

  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          {t(translationKey.LabelSelfSignUpSettings)}
        </StyledDetailedSection>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableSelfSignUp)}
                              formLabelValue="EnableSelfSignUpPage"
                              name="self_sign_up_settings.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <Row>
        <Grid size={ {xs: leftColumnWidth} }>
          <StyledLabel align="left">
            {t(translationKey.LabelEntityLogo)}
          </StyledLabel>
        </Grid>
        <Grid size={ {xs: rightColumnWidth} } style={ { display: 'flex', alignItems: 'center' } }>
          <Button variant="contained" component="label" disabled={!enabled} style={{ padding: "0.5rem 1rem", background: 'lightgrey', color: 'black', borderRadius: '4px' }}>
            {t(translationKey.ButtonUploadFile)}
            <input id='contained-button-file' type="file" name="file" accept={ ['image/png', 'image/jpeg', 'image/svg+xml'] }
                   hidden
                   onChange={ e => {
                     updateLogo(e);
                   } }
            />
          </Button>
          {
            displayImage ?
              <StyledImage src={ displayImage } alt={t(translationKey.AltUserUploadedLogo)} /> :
              <StyledImageDiv/>
          }
          <Error message={ errors.logo && errors.logo } />
        </Grid>
      </Row>
      <TextFieldWithTitleAndBaseUrl title={t(translationKey.LabelCustomizeSignUpPageUrl)}
                                    leftColumnWidth={ leftColumnWidth }
                                    rightColumnWidth={ rightColumnWidth }
                                    name="self_sign_up_settings.url"
                                    onBlur={ handleBlur }
                                    value={ self_sign_up_settings.url }
                                    onChange={ handleChange }
                                    label={t(translationKey.LabelUrl)}
                                    enabled={enabled}
                                    baseUrl={ process.env.REACT_APP_SIGNUP_URL }
                                    helperText={ errors.url && errors.url }
      />
      <Row>
        <Grid size={ {xs: leftColumnWidth} }>
          <StyledLabel align="left">
            {t(translationKey.LabelAssignSelfSignUpDistributor)}
          </StyledLabel>
        </Grid>
        <Grid size={ {xs: rightColumnWidth} }>
          { self_sign_up_settings.distributor_id &&
          <Typography align={'left'}>
            { getChain(distributors.find(d => d.id === self_sign_up_settings.distributor_id), "name") }
          </Typography>
          }
          <StyledFormControl variant="outlined">
            <InputLabel ref={ inputLabel } id="gender-select-outlined-label" shrink>
              {t(translationKey.TitleDistributor)}
            </InputLabel>
            <Select
              label={t(translationKey.TitleDistributor)}
              disabled={ !enabled }
              labelId="gender-select-outlined-label"
              id="gender-select-outlined"
              name="self_sign_up_settings.distributor_id"
              value={ self_sign_up_settings?.distributor_id || '' }
              defaultValue={ self_sign_up_settings?.distributor_id || '' }
              onChange={ handleChange }
              onBlur={ handleBlur }
              sx={ { width: '100%' } }
              MenuProps={{ MenuListProps: { 
                sx: {"li.MuiButtonBase-root": {
                  display: "flex",
                  flexDirection: "column",
              }}}}}
            >
              <MenuItem key={"#"} disabled={true}>
                <em>{t(translationKey.LabelSelectEllipses)}</em>
              </MenuItem>
              {
                distributors.map((d,i) =>
                  <MenuItem key={ i } value={ d.id }>{ d.name }</MenuItem>
                )
              }
            </Select>
          </StyledFormControl>
          <Error message={ errors.distributor_id && errors.distributor_id } />
        </Grid>
      </Row>
    </>
  )
}

function FormFieldMarketingVisibility({rowLabel, checkboxName, checkboxTitle, handleChange, leftColumnWidth, rightColumnWidth, enabled, checkboxLabelValue}) {
  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          { rowLabel }
        </StyledDetailedSection>
      </Row>

      <CheckboxFieldWithTitle
        title={checkboxTitle}
        formLabelValue={checkboxLabelValue}
        value={enabled}
        rightColumnWidth={rightColumnWidth}
        leftColumnWidth={leftColumnWidth}
        onChange={handleChange}
        name={checkboxName}
        checked={enabled}
      />
    </>
  )
}

function FormFieldWebhook({ values: { webhook_settings }, handleChange, handleBlur, leftColumnWidth, rightColumnWidth, errors }) {
  const { t } = useTranslation();

  const enabled = webhook_settings.enable;
  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          {t(translationKey.LabelWebhookSettings)}
        </StyledDetailedSection>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableWebhook)}
                              formLabelValue="WebhookEnable"
                              name="webhook_settings.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelWebhookUrl)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="webhook_settings.url"
                          onBlur={ handleBlur }
                          value={ webhook_settings.url }
                          onChange={ handleChange }
                          label={t(translationKey.LabelUrl)}
                          enabled={ enabled }
                          required={ enabled }
                          helperText={ errors.webhook_url && errors.webhook_url }
                          slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
      />
    </>
  )
}

function FormFieldCustomSupportInfo({ values: { custom_support_info }, handleChange, handleBlur, leftColumnWidth, rightColumnWidth }) {

  const { t } = useTranslation();

  const enabled = custom_support_info.enable;
  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          {t(translationKey.LabelCustomSupportInfo)}
        </StyledDetailedSection>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableCustomSupportInfo)}
                              formLabelValue="CustomSupportInfoEnable"
                              name="custom_support_info.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelEntityName)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.entity_name"
                          onBlur={ handleBlur }
                          value={ custom_support_info.entity_name }
                          onChange={ handleChange }
                          label={t(translationKey.LabelEntityName)}
                          enabled={ enabled }
      />
      <TextFieldWithTitle title={t(translationKey.TitleEmail)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.email"
                          onBlur={ handleBlur }
                          value={ custom_support_info.email }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitleEmail)}
      />
      <TextFieldWithTitle title={t(translationKey.TitlePhone)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.phone"
                          onBlur={ handleBlur }
                          value={ custom_support_info.phone }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitlePhone)}
      />
      <TextFieldWithTitle title={t(translationKey.TitleWebsite)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.website"
                          onBlur={ handleBlur }
                          value={ custom_support_info.website }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitleWebsite)}
      />
    </>
  )
}

function FormFieldServiceUserReference({ values: { service_user_reference_field }, handleChange, leftColumnWidth, rightColumnWidth, handleBlur }) {
  const { t } = useTranslation();

  const enabled = service_user_reference_field.show;
  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          {t(translationKey.LabelServiceUserReferenceField)}
        </StyledDetailedSection>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelShowServiceUserReference)}

                              formLabelValue="ServiceUserReferenceFieldShow"
                              name="service_user_reference_field.show"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelCustomTitle)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="service_user_reference_field.custom_title"
                          onBlur={ handleBlur }
                          value={ service_user_reference_field.custom_title }
                          onChange={ handleChange }
                          label={t(translationKey.LabelCustomTitle)}
                          enabled={enabled}
                          required={enabled}
      />
    </>
  )
}

function FormFieldCustomEntry({ rowLabel, textFieldName, value, enabled, checkboxName, checkboxTitle, checkboxLabelValue, handleChange, leftColumnWidth, rightColumnWidth, handleBlur, dropdownName, dropdownCheckboxLabelValue, dropdownCheckboxTitle, dropdownEnabled, dropdownFieldName, dropdownValue }) {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          { rowLabel }
        </StyledDetailedSection>
      </Row>
      <CheckboxFieldWithTitle title={ checkboxTitle }

                              formLabelValue={checkboxLabelValue}
                              name={ checkboxName }
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelCustomTitle)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name={ textFieldName }
                          onBlur={ handleBlur }
                          value={ value }
                          onChange={ handleChange }
                          label={t(translationKey.LabelCustomTitle)}
                          enabled={enabled}
                          required={enabled}
      />
      <CheckboxFieldWithTitle title={ dropdownCheckboxTitle }
                              formLabelValue={dropdownCheckboxLabelValue}
                              name={ dropdownName }
                              value={ dropdownEnabled }
                              checked={ dropdownEnabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
                              disabled={!enabled}
      />
      <TextFieldWithTitle title={t(translationKey.LabelCustomDropdownTitle)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name={ dropdownFieldName }
                          onBlur={ handleBlur }
                          value={ typeof dropdownValue === "string" ? dropdownValue : JSON.stringify(dropdownValue) }
                          onChange={ handleChange }
                          label={t(translationKey.LabelCustomDropdownLabel)}
                          enabled={dropdownEnabled}
                          required={dropdownEnabled}
                          multiline
      />
    </>
  )
}

// Basic form field components
function TextFieldWithTitle({leftColumnWidth, rightColumnWidth, title, subtitle, enabled, value, ...props}) {
  return (
    <Row>
      <Grid size={{xs: leftColumnWidth}}>
        <StyledLabel align="left">
          {title}
        </StyledLabel>
        {subtitle && (
          <StyledLabel align="left">
            {subtitle}
          </StyledLabel>
        )}
      </Grid>
      <Grid size={{xs: rightColumnWidth}}>
        <StyledTextField
          variant="outlined"
          type="text"
          fullWidth
          disabled={!enabled}
          value={value || ''} 
          {...props}
        />
      </Grid>
    </Row>
  )
}

function TextFieldWithTitleAndBaseUrl({ leftColumnWidth, rightColumnWidth, title, baseUrl, enabled, value, ...props }) {

  // const { views: { mobile } } = useViewport();

  return (
    <Row>
      <Grid size={ {xs: leftColumnWidth} }>
        <StyledLabel align="left">
          { title }
        </StyledLabel>
      </Grid>
      <Grid size={ {xs: rightColumnWidth} }>
        <Grid container>
          {/* <Grid size={ {xs: mobile ? 8 : 6} } style={ { display: 'flex', alignItems: 'center' } }>
            <Typography align="left" noWrap>{ baseUrl }</Typography>
          </Grid> */}
          <Grid size={ {xs: 12} }>
            <StyledTextField
              disabled={!enabled}
              variant="outlined"
              type="text"
              value={ value || '' }
              sx={{ whiteSpace: "nowrap"}}
              slotProps={{
                input: { startAdornment: baseUrl }
              }}
              // startAdornment={ baseUrl }
              { ...props }
            />
          </Grid>
        </Grid>
      </Grid>
    </Row>
  )
}

function CheckboxFieldWithTitle({ leftColumnWidth, rightColumnWidth, title, formLabelValue, value, ...props }) {
  const { t } = useTranslation();

  return (
    <Row>
      <Grid size={ {xs: leftColumnWidth} }>
        <StyledLabel align="left">
          { title }
        </StyledLabel>
      </Grid>
      <Grid size={ {xs: rightColumnWidth} }>
        <StyledFormControlLabel
          value={ formLabelValue }
          control={
            <Checkbox
              color="primary"
              // inputProps={{ 'aria-label': t(translationKey.AccessibilityLabelSecondaryCheckbox) }}
              slotProps={{ input: { 'aria-label': t(translationKey.AccessibilityLabelSecondaryCheckbox) } }}
              value={ value || false }
              { ...props }
            />
          }
          label="" />
      </Grid>
    </Row>
  )
}

const TextFieldWithEditor = ({leftColumnWidth, rightColumnWidth, title, subtitle, enabled, value,  ...props}) => {
  const config = useMemo(() => ({
    readOnly: !enabled,
    toolbar: {
      options: ['inline', 'list'],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
      },
      list: {
        options: ['unordered', 'ordered'],
      },
    }
  }), [enabled])

  const [isFocused, setIsFocus] = useState(false);

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value || ''))));


  useEffect(()=>{
    if(isFocused || !value){
      return;
    }
    setEditorState( EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value))));
    setIsFocus(true);
  },[value, isFocused, config]);

  const onChange = newState => {
    setEditorState(newState);
    const html = stateToHTML(newState.getCurrentContent());
    props.onChange({target:{name:`restrict_postcode_settings.custom_support_text`, value:html}})
  }

  return (
    <>
      {
        <Row>
          <Grid size={{xs: leftColumnWidth}}>
            <StyledLabel align="left">
                {title}
            </StyledLabel>
            {subtitle && (
                <StyledLabel align="left">
                    {subtitle}
                </StyledLabel>
            )}
          </Grid>
          <Grid size={{xs: rightColumnWidth}}>
            <Card>
              <Editor
                  editorState={editorState}
                  onEditorStateChange={onChange}
                  toolbar={config.toolbar}
                  readOnly={config.readOnly}
              />
            </Card>
           </Grid>
          </Row>
        }
    </>
    )
}

function FormFieldCustomErrors({values: {restrict_postcode_settings},
                                 handleChange,
                                 handleBlur,
                                 leftColumnWidth,
                                 rightColumnWidth,
                               }) {

  const {t} = useTranslation();
  const enabled = restrict_postcode_settings?.enable_custom_error_message || false;

  return (
    <>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableCustomErrorMessage)}
                              formLabelValue="CustomErrorEnable"
                              name="restrict_postcode_settings.enable_custom_error_message"
                              value={enabled}
                              checked={enabled}
                              onChange={handleChange}
                              leftColumnWidth={leftColumnWidth}
                              rightColumnWidth={rightColumnWidth}
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorMessage)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_message"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_message}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorMessage)}
                          enabled={enabled}
                          required={enabled}
                          multiline
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorCTAMessage)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_cta_message"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_cta_message}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorCTAMessage)}
                          enabled={enabled}
                          multiline
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorCTAURL)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_cta_url"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_cta_url}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorCTAURL)}
                          enabled={enabled}
                          multiline
      />
    </>
  )
}

function FormFieldRestrictions({values: {restrict_postcode_settings},
                                 handleChange,
                                 handleBlur,
                                 leftColumnWidth,
                                 rightColumnWidth,
                                 inputLabel,
                                 labelWidth,
                               }) {

  const {t} = useTranslation();

  const enabled = restrict_postcode_settings?.enable || false;
  const validationRegex = /^[A-Za-z0-9,]*$/;
  let isValid = true;
  if(restrict_postcode_settings?.patterns?.length){
    isValid = validationRegex.test(restrict_postcode_settings.patterns)
  }

  return (
    <>
      <Row>
        <StyledDetailedSection align="left">
          {t(translationKey.TitleRestrictionSettings)}
        </StyledDetailedSection>
      </Row>

      <CheckboxFieldWithTitle title={t(translationKey.LabelRestrictPostCode)}
                              formLabelValue="RestrictPostcodeEnable"
                              name="restrict_postcode_settings.enable"
                              value={enabled}
                              checked={enabled}
                              onChange={handleChange}
                              leftColumnWidth={leftColumnWidth}
                              rightColumnWidth={rightColumnWidth}
      />

      <Row>
        <Grid size={{xs: leftColumnWidth}}>
          <StyledLabel  align="left">
            {t(translationKey.LabelRestrictSignupCountryTo)}
          </StyledLabel>
        </Grid>

        <Grid size={{xs: rightColumnWidth}}>
          <StyledFormControl variant="outlined">
            <InputLabel ref={inputLabel} id="country-select-outlined-label">
              {t(translationKey.LabelRestrictSignupCountryTo)}
            </InputLabel>
            <Select
              label={t(translationKey.LabelRestrictSignupCountryTo)}
              disabled={!enabled}
              labelId="country-select-outlined-label"
              id="country-select-outlined"
              name="restrict_postcode_settings.country"
              value={restrict_postcode_settings?.country || ''}
              defaultValue={restrict_postcode_settings?.country || ''}
              // labelWidth={labelWidth}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={ { width: '100%' } }
              MenuProps={{ MenuListProps: { 
                sx: {"li.MuiButtonBase-root": {
                  display: "flex",
                  flexDirection: "column",
              }}}}}
            >
              <MenuItem key={"#"} disabled={true}>
                <em>{t(translationKey.LabelSelectEllipses)}</em>
              </MenuItem>
              {
                countryList.map((country, index) =>
                  <MenuItem key={index} value={country}>{country}</MenuItem>
                )
              }
            </Select>
            
          </StyledFormControl>
        </Grid>
      </Row>

      <TextFieldWithTitle title={t(translationKey.LabelAcceptPostCodesStartingWith)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.patterns"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.patterns}
                          onChange={handleChange}
                          label={t(translationKey.LabelAcceptPostCodesStartingWith)}
                          enabled={enabled}
                          required={enabled}
                          subtitle={t(translationKey.SubTitleExamplePatternText)}
                          multiline
                          error={!isValid}
                          helperText={!isValid? t(translationKey.InvalidPattern):''}
      />

        <TextFieldWithTitle title={t(translationKey.LabelAvailabilityText)}
                            leftColumnWidth={leftColumnWidth}
                            rightColumnWidth={rightColumnWidth}
                            name="restrict_postcode_settings.availability_text"
                            onBlur={handleBlur}
                            value={restrict_postcode_settings?.availability_text}
                            onChange={handleChange}
                            label={t(translationKey.LabelAvailabilityText)}
                            enabled={enabled}
                            required={enabled}
                            subtitle={t(translationKey.SubTitleAvailabilityText)}
                            multiline
        />

      <TextFieldWithEditor title={t(translationKey.LabelCustomSupportText)}
                           leftColumnWidth={leftColumnWidth}
                           rightColumnWidth={rightColumnWidth}
                           name="restrict_postcode_settings?.custom_support_text"
                           onBlur={handleBlur}
                           value={restrict_postcode_settings?.custom_support_text}
                           onChange={handleChange}
                           label={t(translationKey.LabelCustomSupportText)}
                           enabled={enabled}
                           required={enabled}
                           subtitle={t(translationKey.SubTitleCustomSupportText)}
                           multiline
      />
    </>
  )
}


function Row({ children }) {
  const { views: { mobile } } = useViewport();

  return (
    <Grid container direction={ mobile ? 'column' : 'row' }
          justify="space-between" alignItems={ mobile ? '' : 'center' } style={ { margin: '0.5rem 0' } }>
      { children }
    </Grid>
  )
}
